import { v4 as uuidv4 } from 'uuid';

// MSAL depends on randomUUID
if (window.crypto.randomUUID == null) {
  window.crypto.randomUUID = () => {
    const result =
      uuidv4() as `${string}-${string}-${string}-${string}-${string}`;
    return result;
  };
}
